<template>
  <div class="absolute top-full left-0 right-0 pt-35 pb-25 z-20 bg-white shadow-md overflow-y-auto lg:overflow-y-visible" id="global-search-box">
    <div class="max-w-[600px] mx-auto">
      <SearchForm id="globalSearch" @search="closeSearch" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  searchActive: {
    type: Boolean
  }
})

const emit = defineEmits<{ (e: 'close'): void }>();

/**
 * Emit close signal, to close the burger menu.
 *
 * @returns void
 */
const closeSearch = () => {
  emit('close');
};

/**
 * Vue mounted hook.
 */
onMounted(() => {
  // Listen for clicks outside searchbox.
  const searchbox = document.querySelector('#global-search-box');
  setTimeout(() => {
    document.body.addEventListener('click', function (event: any) {
      if (searchbox && !searchbox.contains(event.target)) {
        closeSearch()
      }
    });
  }, 500)
});
</script>
